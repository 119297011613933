<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        class="options-btn"
        v-on="on"
      >
        <v-icon>mdi-dots-horizontal</v-icon>
      </v-btn>
    </template>
    <v-list class="options-list">
      <v-list-item
        v-for="(option, idx) in options"
        :key="idx"
        class="list-item"
        :class="{ 'list-item-disabled': option.disabled }"
        dense
        @click="handleOptionClick(option, $event)"
      >
        <v-list-item-icon>
          <v-icon>{{ option.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          <template v-if="option.tooltip">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <span
                  class="list-item-title-disabled"
                  v-on="on"
                >{{ $t(option.label) }}</span>
              </template>
              <span>{{ option.tooltip }}</span>
            </v-tooltip>
          </template>
          <template v-else>
            {{ $t(option.label) }}
          </template>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import DeletePipelineStatusDialog from '@/modules/leads/LeadList/Cards/DeletePipelineStatusDialog.vue'
import AddPipelineStatusDialog from '@/modules/leads/LeadList/Cards/AddPipelineStatusDialog.vue'

export default {
  props: {
    pipeline: {
      type: Object,
      required: true
    }
  },
  computed: {
    options () {
      return [
        {
          label: 'leads-pipeline-options.0',
          icon: 'mdi-pencil-outline',
          disabled: !this.pipeline.isEditable,
          tooltip: !this.pipeline.isEditable ? this.$t('leads-pipeline-tooltip.0') : null,
          action: this.editColumn
        },
        {
          label: 'leads-pipeline-options.1',
          icon: 'mdi-arrow-collapse-left',
          disabled: this.pipeline.isFirst,
          tooltip: this.pipeline.isFirst ? this.$t('leads-pipeline-tooltip.2') : null,
          action: () => this.updateStatusPosition('left')
        },
        {
          label: 'leads-pipeline-options.2',
          icon: 'mdi-arrow-collapse-right',
          disabled: this.pipeline.isLast,
          tooltip: this.pipeline.isLast ? this.$t('leads-pipeline-tooltip.3') : null,
          action: () => this.updateStatusPosition('right')
        },
        {
          label: 'leads-pipeline-options.3',
          icon: 'mdi-arrow-right',
          disabled: false,
          tooltip: null,
          action: this.addColumn
        },
        {
          label: 'leads-pipeline-options.4',
          icon: 'mdi-trash-can-outline',
          disabled: !this.pipeline.isEditable || this.pipeline.leads.length > 0,
          tooltip: !this.pipeline.isEditable
            ? this.$t('leads-pipeline-tooltip.1')
            : this.pipeline.leads.length > 0
              ? this.$t('leads-pipeline-tooltip.4')
              : null,
          action: this.deleteColumn
        }
      ]
    }
  },
  methods: {
    handleOptionClick (option, event) {
      if (option.disabled) {
        event.stopPropagation()
        return
      }
      option.action()
    },
    editColumn () {
      this.$emit('activeDialog', AddPipelineStatusDialog)
      this.$emit('isEdit', true)
      this.$emit('activePipeline', this.pipeline)
    },
    updateStatusPosition (position) {
      this.$emit('updateStatusPosition', position)
    },
    addColumn () {
      this.$emit('activeDialog', AddPipelineStatusDialog)
      this.$emit('isEdit', false)
      this.$emit('activePipeline', this.pipeline)
    },
    deleteColumn () {
      this.$emit('activeDialog', DeletePipelineStatusDialog)
      this.$emit('activePipeline', this.pipeline)
    }
  }
}

</script>

<style scoped>
.options-btn {
  align-self: start;
}

.options-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: #FFFFFF;
  box-shadow: 0 4px 6px 0 rgba(32, 33, 36, 0.28);
}

.v-list {
  padding: 0 !important;
}

.v-sheet.v-list:not {
  box-shadow: none !important;
}

.list-item {
  display: flex;
  padding: 10px 16px !important;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px 4px 0 0;
  background: #FFFFFF;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-item-title {
  color: #252525;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0 !important;
}

.list-item:hover {
  background-color: #E6F8F8;
}

.list-item:hover .v-icon,
.list-item:hover .list-item-title {
  color: #06BEB8;
}

.list-item-disabled {
  opacity: 0.5;
}

.list-item-disabled:hover {
  background-color: transparent;
}

.list-item-disabled:hover .list-item-title,
.list-item-disabled:hover .v-icon{
  color: #E4E4E4;
}
</style>
