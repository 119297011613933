import DELETE_LEADS_PIPELINE_STATUS from '@/modules/leads/LeadList/Cards/queries/DeleteLeadsPipelineStatus.gql'
import ADD_LEADS_PIPELINE_STATUS from '@/modules/leads/LeadList/Cards/queries/AddLeadsPipelineStatus.gql'
import UPDATE_LEADS_PIPELINE_STATUS_POSITION from '@/modules/leads/LeadList/Cards/queries/UpdateLeadsPipelineStatusPosition.gql'
import UPDATE_LEADS_PIPELINE_STATUS from '@/modules/leads/LeadList/Cards/queries/UpdateLeadsPipelineStatus.gql'
import GET_LEADS_PIPELINE_STATUSES from '@/modules/leads/LeadList/Cards/queries/GetLeadsPipelineStatuses.gql'

import { showSnackbarMessage } from '@/lib/snackbarMessages'
import * as Sentry from '@sentry/vue'

export default {
  methods: {
    async addStatus (newStatus, { position }) {
      this.status = 'processing'
      const newPosition = position + 1

      try {
        const { data } = await this.$apollo.mutate({
          mutation: ADD_LEADS_PIPELINE_STATUS,
          refetchQueries: [{ query: GET_LEADS_PIPELINE_STATUSES }],
          variables: {
            input: { name: newStatus, position: newPosition }
          }
        })

        if (data.addLeadsPipelineStatus) {
          const { statuses, newStatusId } = data.addLeadsPipelineStatus
          const newStatusIndex = this.leadsList.findIndex((status) => status.position === position) + 1
          this.leadsList.splice(newStatusIndex, 0, {
            id: newStatusId,
            isEditable: true,
            leads: [],
            page: 1,
            position: newPosition,
            status: newStatus,
            title: newStatus,
            total: 0,
            isLast: false,
            isFirst: false
          })

          this.updatePositionsAndIsLastAndIsFirst(this.leadsList, newStatusIndex, statuses)

          this.$tracking.event('Leads', 'Clicked', 'Added Pipeline Status')
          this.status = 'success'
        }
      } catch (error) {
        Sentry.captureException(error)
        showSnackbarMessage('error', this.$t('alerts.add-lead-pipeline.error'))
        this.status = 'error'
      }
    },

    async updateStatusPosition (movingDirection, index) {
      if (!['left', 'right'].includes(movingDirection)) {
        return
      }

      try {
        const newIndex = movingDirection === 'left' ? index - 1 : index + 1
        const newPosition = this.leadsList[newIndex].position
        const oldPosition = this.leadsList[index].position
        const oldStatusId = this.leadsList[index].id
        const swappedStatusId = this.leadsList[newIndex].id

        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_LEADS_PIPELINE_STATUS_POSITION,
          refetchQueries: [{ query: GET_LEADS_PIPELINE_STATUSES }],
          variables: {
            input: { swappedStatusId, oldStatusId }
          }
        })

        if (data.updateLeadsPipelineStatusPosition) {
          const [movedItem] = this.leadsList.splice(index, 1)
          this.leadsList.splice(newIndex, 0, movedItem)

          this.leadsList[newIndex].position = newPosition
          this.leadsList[index].position = oldPosition

          this.updateIsLastAndIsFirst(this.leadsList)

          this.$tracking.event('Leads', 'Clicked', 'Pipeline Status Position Updated')
          showSnackbarMessage('success', this.$t('alerts.update-lead-pipeline-position.success'))
        }
      } catch (error) {
        Sentry.captureException(error)
        showSnackbarMessage('error', this.$t('alerts.update-lead-pipeline-position.error'))
      }
    },

    async deleteStatus ({ id }) {
      if (!id) {
        return
      }

      const statusToDelete = this.leadsList.find(lead => lead.id === id)
      if (statusToDelete?.leads?.length > 0) {
        showSnackbarMessage('error', this.$t('alerts.delete-lead-pipeline.has-leads'))
        return
      }

      this.status = 'processing'

      try {
        const { data } = await this.$apollo.mutate({
          mutation: DELETE_LEADS_PIPELINE_STATUS,
          refetchQueries: [{ query: GET_LEADS_PIPELINE_STATUSES }],
          variables: {
            input: {
              id
            }
          }
        })

        if (data.deleteLeadsPipelineStatus) {
          const { id } = data.deleteLeadsPipelineStatus
          this.leadsList = this.leadsList.filter((lead) => lead.id !== id)
          this.updateIsLastAndIsFirst(this.leadsList)
          this.$tracking.event('Leads', 'Clicked', 'Deleted Pipeline Status')
          this.status = 'success'
        }
      } catch (error) {
        Sentry.captureException(error)
        showSnackbarMessage('error', this.$t('alerts.delete-lead-pipeline.error'))
        this.status = 'error'
      }
    },

    async updateStatus (newStatus, { id, status }) {
      if (!newStatus?.trim() || !id || !status?.trim()) {
        return
      }

      this.status = 'processing'

      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_LEADS_PIPELINE_STATUS,
          refetchQueries: [{ query: GET_LEADS_PIPELINE_STATUSES }],
          variables: {
            input: {
              newStatusName: newStatus,
              oldStatusName: status,
              statusId: id
            }
          }
        })

        if (data.updateLeadsPipelineStatus) {
          const { id } = data.updateLeadsPipelineStatus
          this.leadsList.forEach((lead) => {
            if (lead.id === id) {
              this.$set(lead, 'status', newStatus)
              this.$set(lead, 'title', newStatus)
            }
          })
          this.$tracking.event('Leads', 'Clicked', 'Pipeline Status Updated')
          this.status = 'success'
        }
      } catch (error) {
        Sentry.captureException(error)
        showSnackbarMessage('error', this.$t('alerts.update-lead-pipeline-status.error'))
        this.status = 'error'
      }
    },

    updateIsLastAndIsFirst (leadsList) {
      leadsList.forEach((lead, index) => {
        this.$set(lead, 'isLast', index === leadsList.length - 1)
        this.$set(lead, 'isFirst', index === 0)
      })
    },

    updatePositionsAndIsLastAndIsFirst (leadsList, startIndex, updatedStatuses) {
      leadsList.forEach((status, index) => {
        if (index >= startIndex) {
          const updatedPosition = updatedStatuses.find(
            (lead) => lead.name === status.status
          )?.position

          if (updatedPosition !== undefined) {
            this.$set(leadsList[index], 'position', updatedPosition)
          }
        }

        this.$set(status, 'isLast', index === leadsList.length - 1)
        this.$set(status, 'isFirst', index === 0)
      })
    }
  }
}
