<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <slot name="activator" />
      </div>
    </template>
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title class="dialog-title">
            <span v-if="isEdit">{{ $t('rename-lead-pipeline.title') }}</span>
            <span v-else>{{ $t('add-lead-pipeline.title') }} </span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>
      <v-form
        class="mt-3"
        @submit.prevent="addStatus"
      >
        <v-card-text class="px-4 py-6 dialog-text">
          <div v-if="status === 'idle'">
            <span v-if="isEdit">{{ $t('rename-lead-pipeline.text') }}</span>
            <span v-else>{{ $t('add-lead-pipeline.text') }}</span>

            <v-text-field
              v-model="title"
              class="my-2"
              :label="$t('add-lead-pipeline.label')"
              outlined
            />
          </div>
          <ProgressCircular
            v-if="status === 'processing'"
            :size="60"
          />
          <div
            v-if="status === 'success'"
            class="d-flex flex-column overflow-x-hidden"
          >
            <v-icon
              size="60"
              color="success"
            >
              mdi-check-circle
            </v-icon><v-spacer />
            <div class="mt-4 status-msg">
              <span v-if="isEdit">{{ $t('alerts.rename-lead-pipeline.success') }}</span>
              <span v-else>{{ $t('alerts.add-lead-pipeline.success') }}</span>
            </div>
          </div>
          <div
            v-if="status === 'error'"
            class="d-flex flex-column overflow-x-hidden"
          >
            <v-icon
              size="60"
              class="error--text"
            >
              mdi-close-circle
            </v-icon>
            <div class="mt-4 status-msg">
              <span v-if="isEdit">{{ $t('alerts.rename-lead-pipeline.error') }}</span>
              <span v-else>{{ $t('alerts.add-lead-pipeline.error') }}</span>
            </div>
          </div>
        </v-card-text>

        <v-card-actions
          v-if="status === 'idle'"
          class="pt-0"
        >
          <v-spacer />
          <v-btn
            text
            color="primary"
            :disabled="!title || title.length === 0"
            type="submit"
          >
            <span>{{ $t('buttons.add-lead-pipeline') }} </span>
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import ProgressCircular from '@/components/ProgressCircular.vue'

export default {
  components: { ProgressCircular },
  props: {
    pipeline: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      required: true
    },
    loadingStatus: {
      type: String,
      default: 'idle',
      validator: value => ['idle', 'processing', 'success', 'error'].includes(value)
    }
  },
  data () {
    return {
      dialog: true,
      status: 'idle',
      title: this.isEdit ? this.$t(this.pipeline.title) : '',
      debounceTimeout: null
    }
  },
  watch: {
    loadingStatus (newVal) {
      this.status = newVal

      if (newVal === 'success') {
        this.debounceTimeout = setTimeout(() => {
          this.closeDialog()
        }, 3000)
      }
    }
  },
  beforeDestroy () {
    this.closeDialog()
  },
  methods: {
    closeDialog () {
      clearTimeout(this.debounceTimeout)
      this.$emit('close')
    },

    addStatus () {
      const emitEvent = this.isEdit ? 'updateStatus' : 'addStatus'
      this.$emit(emitEvent, this.title)
    }
  }
}
</script>

<style scoped>
.dialog-text span {
    color: #414142;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.dialog-title span {
    color: #FFFFFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
.status-msg {
  align-self: center;
  color: #252525;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
</style>
