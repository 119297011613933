import { dueDateFilter } from '@/modules/leads/LeadList/enums/DueDateFilter'

export const availableDueDates = [
  dueDateFilter.DUE_DATE_TODAY,
  dueDateFilter.DUE_DATE_THIS_WEEK,
  dueDateFilter.DUE_DATE_NEXT_WEEK,
  dueDateFilter.DUE_DATE_THIS_MONTH,
  dueDateFilter.DUE_DATE_NEXT_MONTH,
  dueDateFilter.WITHOUT_DUE_DATE,
  dueDateFilter.DUE_DATE_OVERDUE
]

export const dueDates = [
  { key: dueDateFilter.DUE_DATE_TODAY, label: 'today' },
  { key: dueDateFilter.DUE_DATE_THIS_WEEK, label: 'this-week' },
  { key: dueDateFilter.DUE_DATE_NEXT_WEEK, label: 'next-week' },
  { key: dueDateFilter.DUE_DATE_THIS_MONTH, label: 'this-month' },
  { key: dueDateFilter.DUE_DATE_NEXT_MONTH, label: 'next-month' },
  { key: dueDateFilter.WITHOUT_DUE_DATE, label: 'without-due-date' },
  { key: dueDateFilter.DUE_DATE_OVERDUE, label: 'overdue' }
]
