<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        v-on="on"
      >
        <slot name="activator" />
      </div>
    </template>
    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="error"
          dark
          flat
        >
          <v-toolbar-title class="dialog-title">
            <v-icon
                medium
                left>
              mdi-alert
            </v-icon>
            {{ $t('delete-lead-pipeline.title') }}
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="px-4 py-6">
        <div
          v-if="status === 'idle'"
          class="dialog-text"
        >
          {{ $t('delete-lead-pipeline.text') }}
        </div>

        <ProgressCircular
          v-if="status === 'processing'"
          :size="60"
        />

        <div
          v-if="status === 'success'"
          class="d-flex flex-column overflow-x-hidden"
        >
          <v-icon
            size="60"
            color="success"
          >
            mdi-check-circle
          </v-icon><v-spacer />
          <div class="mt-4 status-msg">
            {{ $t('alerts.delete-lead-pipeline.success') }}
          </div>
        </div>
        <div
          v-if="status === 'error'"
          class="d-flex flex-column overflow-x-hidden"
        >
          <v-icon
            size="60"
            class="error--text"
          >
            mdi-close-circle
          </v-icon>
          <div class="mt-4 status-msg">
            {{ $t('alerts.delete-lead-pipeline.error') }}
          </div>
        </div>
      </v-card-text>

      <v-card-actions
        v-if="status === 'idle'"
        class="pt-0"
      >
        <v-btn
            text
            @click="closeDialog"
        >
          {{ $t('labels.cancel') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          color="error"
          @click="deleteStatus"
        >
          {{ $t('buttons.delete-lead-pipeline') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import ProgressCircular from '@/components/ProgressCircular.vue'

export default {
  components: { ProgressCircular },
  props: {
    loadingStatus: {
      type: String,
      default: 'idle',
      validator: value => ['idle', 'processing', 'success', 'error'].includes(value)
    }
  },
  data () {
    return {
      dialog: true,
      status: 'idle',
      debounceTimeout: null
    }
  },
  watch: {
    loadingStatus (newVal) {
      this.status = newVal

      if (newVal === 'success') {
        this.debounceTimeout = setTimeout(() => {
          this.closeDialog()
        }, 3000)
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.debounceTimeout)
    this.closeDialog()
  },
  methods: {
    closeDialog () {
      this.$emit('close')
    },

    async deleteStatus () {
      this.$emit('deleteStatus')
    }
  }
}
</script>

<style scoped>
.dialog-text {
    color: #414142;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.dialog-title {
    color: #FFFFFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
}
.status-msg {
  align-self: center;
  color: #252525;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
}
</style>
